export function formatDate(date, fmt = 'yyyy-MM-dd hh:mm:ss') {
  let o = {
    'M+': date.getMonth() + 1, //月份
    'd+': date.getDate(), //日
    'h+': date.getHours(), //小时
    'm+': date.getMinutes(), //分
    's+': date.getSeconds(), //秒
    'q+': Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
    }
  }
  return fmt;
}

export function getRangeTimestampByNow(begin, end) {
  let timestamps = [];
  const nowTime = new Date().getTime();
  const oneDayTime = 1000 * 3600 * 24;

  if (end == undefined) {
    timestamps.push(nowTime + oneDayTime * begin);
  } else {
    if (end >= begin) {
      for (let i = begin; i <= end; i++) {
        timestamps.push(nowTime + oneDayTime * i);
      }
    } else {
      for (let i = begin; i >= end; i--) {
        timestamps.push(nowTime + oneDayTime * i);
      }
    }
  }
  return timestamps;
}
