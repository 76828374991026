<template>
  <div class="orders-content">
    <div class="headnav"><i class="icon" @click="$router.go(-1)"></i>My Bets</div>
    <div class="order-table">
      <table class="table">
        <thead>
        <tr class="tab-row tab-head">
          <th class="a">Draw ID</th>
          <th class="b">My<br/>Numbers</th>
          <th class="c">Wager<br/>(KSH)</th>
          <th class="e">Multiplier</th>
          <th class="f">Winnings<br/>(KSH)</th>
        </tr>
        </thead>
      </table>
      <van-collapse v-model="activeName" accordion class="collapse">
        <van-collapse-item v-for="(item, index) in orders" :key="index" :name="index">
          <template #title>
            <table class="table">
              <tbody>
              <tr class="tab-row">
                <td class="a">
                  <div>{{item.actionno}}</div>
                  <div style="color: #008250;">{{waiting(item.actionno) ? "Waiting" : item.res}}</div>
                  <div style="color: #A0A0A0;">{{purchase_data_str(item.actiontime)}}</div>
                </td>
                <td class="b">
                  <div v-html="item.code"></div>
                  <div style="color: #008250;">{{item.playType==1?"Any Order":"Straight Order"}}</div>
                </td>
                <td class="c">{{item.score}}</td>
                <td class="e">{{item.price}}</td>
                <td class="f" style="color: #008250;">
                  <span v-if="item.returnscore>0">{{waiting(item.actionno) ? "Waiting" : (item.returnscore || 0)}}</span>
                  <span v-else>{{waiting(item.actionno) ? "Waiting" : (item.returnscore || 0)}}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </template>
          <!-- <div class="row">
            <span class="name">Draw ID:</span>
            <span class="value">{{item.actionno}}</span>
          </div>
          <div class="row row2">
            <span class="name">Ticket ID:</span>
            <span class="value">{{item.orderid}}</span>
          </div>
          <div class="row row2">
            <span class="name">Multiplier:</span>
            <span class="value">{{item.price}}</span>
          </div> -->
        </van-collapse-item>
      </van-collapse>
      <div class="control-strip">
        <van-button class="button previous" @click="on_previous" v-bind:class="{button2:can_previous}">Previous
        </van-button>
        <div class="text">
          <span class="cur">{{curPage}}</span>
          <span>/</span>
          <span class="max">{{maxPage}}</span>
        </div>
        <van-button class="button next" @click="on_next" v-bind:class="{button2:can_next}">Next</van-button>
      </div>
    </div>
    <!--    <div class="payouts">-->
    <!--      <span class="title">LOTTO 3 Odds & Payouts - Example Table</span>-->
    <!--      <PayoutTable class="payout-table"></PayoutTable>-->
    <!--    </div>-->
  </div>
</template>

<script>
    import {gameType, serve} from "../constant";
    import {formatDate} from "../utils/date";
    import {mapGetters} from "vuex";


    export default {
        data() {
            return {
                activeName: "",
                curPage: 1,
                limit: 10,
                maxPage: 0,
                orders: []
            }
        },
        created() {
            this.requestOrders();
        },
        watch: {
            '$route' () {
              this.requestOrders();
            }
        },
        computed: {
            ...mapGetters([
                "drawId"
            ]),
            can_previous() {
                return this.curPage > 1;
            },
            can_next() {
                return this.curPage < this.maxPage;
            },

        },
        methods: {
            refresh (done) {
                let _this = this;
                setTimeout(() => {
                  _this.requestOrders();
                  done()    //我就想说这里，把状态归0
                }, 500)
            },
            requestOrders() {
                this.$store.dispatch('serve_req', {
                    url: serve.user.history,
                    method: 'get',
                    data: {
                        page: this.curPage,
                        limit: this.limit,
                        typeid: gameType
                    }
                }).then((data) => {
                    this.curPage = data.current;
                    this.maxPage = data.pages;
                    this.orders.splice(0, this.orders.length);
                    data.records.forEach((item, index) => {
                        item.code = this.picksStr(item);
                        this.$set(this.orders, index, item);
                    });
                })
            },
            on_previous() {
                if (this.can_previous) {
                    this.curPage--;
                    this.requestOrders();
                }
            },
            on_next() {
                if (this.can_next) {
                    this.curPage++;
                    this.requestOrders();
                }
            },
            purchase_data_str(timestamp) {
                return formatDate(new Date(timestamp), 'MM-dd\nhh:mm');
            },
            result_str(score) {
                if (score === null || score === 0) {
                    return "";
                }
                return (score > 0) ? "win" : "lose";
            },
            waiting(drawId) {
                return this.drawId === drawId;
            },
            /**
             * @param {Object} code1 开奖号码，格式：1,2,3
             * @param {Object} code2 下注号码，格式：123
             * @param {Object} playType 下注方式，1=不固定位置 2固定位置
             */
            picksStr(item) {
              let code1 = item.res;
              let code2 = item.code;
              if(code1==null){
                return code2.split("").join(",");
              }
              let playType = item.playType;
              let resHtml = "";
              let code1Arr = code1.split(",");
              if(playType==1){
                let code2Arr = code2.split("");
                let resArr = []

                //不固定位置方式的：先把固定位置的标绿，
                for(let i in code2Arr){
                  let numStr = "";
                  if(code2Arr[i]==code1Arr[i]){
                    //数字匹配，标绿
                    numStr ="<span class='theme-color'>"+code2Arr[i]+"</span>";
                    code1Arr[i] = -1;
                    resArr[i] = numStr;
                  }else{
                    numStr = code2Arr[i];
                    resArr[i] = code2Arr[i];
                  }
                }
                for(let i in resArr){
                  let code = resArr[i];
                  let numStr = resArr[i];
                  if(code.length==1){
                    for(let j in code1Arr){
                      if(code==code1Arr[j]){
                        code1Arr[j] = -1;
                        //数字匹配，标绿
                        numStr ="<span class='theme-color'>"+code+"</span>";
                      }
                    }
                  }
                  if(resHtml==""){
                    resHtml+=numStr;
                  }else{
                    resHtml+=","+numStr;
                  }
                }

              }else{
                let code2Arr = code2.split("");
                for(let i in code2Arr){
                  let numStr = "";
                  if(code2Arr[i]==code1Arr[i]){
                    //数字匹配，标绿
                    numStr = "<span class='theme-color'>"+code2Arr[i]+"</span>";
                  }else{
                    numStr = code2Arr[i];
                  }
                  if(resHtml==""){
                    resHtml+=numStr;
                  }else{
                    resHtml+=","+numStr;
                  }
                }
              }
              return resHtml;
            },
        }
    }
</script>

<style scoped lang="less">
  @import "../styles/var";

  .orders-content {
    padding-bottom: 6px;
    margin-top: 104px;
    min-height: @content-min-height;

    /deep/.theme-color{
      color: red;
    }
    .order-table {
      background: #FFFFFF;
      overflow: hidden;

      .table {
        border-spacing: 0;
        border-collapse: collapse;
        tr {
          height: 86px;
        }

        td {
          height: 25px;
          font-size: 22px;
          font-family: ArialMT;
          color: #333333;
          line-height: 32px;
          text-align: left;
          padding: 5px 0;
        }

        .tab-row {
          font-size: 22px;
          font-family: ArialMT;
          color: #333333;
          line-height: 26px;
        }

        .tab-head {
          height: 86px;
          background: #FAFAFA;
          font-size: 22px;
          font-family: Arial-BoldMT, Arial;
          font-weight: normal;
          color: #737373;
          line-height: 26px;
          text-align: left;
          border: none;
        }

        .a {
          width: 180px;
          padding-left: @td-padding;
        }

        .b {
          width: 150px;
        }

        .c {
          width: 130px;
        }

        .d {
          width: 110px;
        }

        .e {
          width: 130px;
        }

        .f {
          width: 130px;
        }
      }

      /deep/ .collapse {
        .van-cell {
          padding: 0;
          border-bottom: 1px solid #EBEBEB;
        }

        .van-collapse-item__content {
          padding: 0;
        }

        .van-collapse-item--border::after {
          border-top: transparent;
        }

        .van-cell::after {
          border-bottom: transparent;
        }

        .van-cell__right-icon {
          margin-top: 30px;
          margin-right: 8px;
        }
        .van-icon{
          display: none;
        }
        .van-icon-arrow::before {
          content: "";
          width: 14px;
          height: 14px;
          background: url("../assets/img_jiantou_r.png") no-repeat center;
          background-size: contain;
        }

        .row {
          height: 50px;
          background: #FFF6EE;
          font-size: 22px;
          font-family: ArialMT;
          color: #333333;
          line-height: 26px;
          display: flex;
          justify-items: flex-start;
          justify-content: space-between;
          align-items: center;
          padding: 0 32px;
        }

        .row2 {
          background-color: #FFFBF7;
        }
      }

      .control-strip {
        display: flex;
        justify-items: flex-start;
        justify-content: center;
        align-items: center;
        padding: 37px 0;

        .button {
          width: 132px;
          height: 38px;
          background: #FFFFFF;
          border-radius: 19px;
          border: 1px solid #D0D0D0;
          font-size: 22px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #C0C0C0;
          line-height: 30px;
        }

        .button2 {
          border: 1px solid #333333;
          color: #333333;
        }

        .text {
          font-size: 22px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 30px;
          margin: 0 35px;

          span {
            margin: 0 3px;
          }

          .cur {
            color: #009901;
          }
        }
      }
    }

    .payouts {
      margin: 0 @margin @margin @margin;
      background: #077A08;
      border-radius: 30px;
      overflow: hidden;

      .title {
        font-size: 32px;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #FFFFFF;
        line-height: 37px;
      }

      .payout-table {
        margin: 20px 0;
      }
    }

  }
</style>
